/*
 * @Author: mulingyuer
 * @Date: 2021-04-25 10:18:01
 * @LastEditTime: 2021-04-25 11:26:33
 * @LastEditors: mulingyuer
 * @Description: 异步队列管理器
 * @FilePath: \user-admin-vue\src\base\utils\async-queue-manager.js
 * 怎么可能会有bug！！！
 */

class AsyncQueueManager {
  constructor(options = {}) {
    this.initOptions(options);
  }

  //初始化基础配置
  initOptions(options) {
    Object.assign(this, {
      maxParallel: 1, //线程数量
      asyncArr: [],  //任务数组
      nextCount: 0, //下一个函数的下标
      successCount: 0, //已完成任务计数器
      status: true,  //管理器状态，true正常，false异常
      successCallBack: function () { }, //每个任务完成后的回调
      errorCallBack: function () { }, //每个任务失败后的回调
      resolve: function () { }, //全部完成回调（占位-实际应该是promise的resolve回调）
      reject: function (error) { throw error; },  //出错的回调（占位-实际应该是promise的reject回调）
      responseArr: [], //成功后的结果集合
    });
    //自定义线程数量
    if (typeof options.maxParallel === "number" && options.maxParallel > 0) {
      this.maxParallel = options.maxParallel;
    }
  }

  //添加任务
  push(fn) {
    this.asyncArr.push(fn);
  }

  //获取下一个任务
  next() {
    return this.asyncArr[this.nextCount++];
  }

  //开始运行
  start(successFn, errorFn) {
    if (typeof successFn === "function") this.successCallBack = successFn;
    if (typeof errorFn === "function") this.errorCallBack = errorFn;
    //返回promise
    return new Promise((resolve, reject) => {
      //promise回调绑定到this
      this.resolve = resolve;
      this.reject = reject;

      //运行设置的线程数量
      for (let i = 0; i < this.maxParallel; i++) {
        const fn = this.next();
        if (!fn) break; //没有任务了则退出for循环
        //运行体
        this.run(fn);
      }
    });
  }

  //运行体
  run(fn) {
    fn().then(res => {
      if (!this.status) return; //如果有异常停止后续运行
      this.responseArr.push(res); //记录结果
      this.successCallBack(res);  //运行每次成功后的回调
      this.successCount++;  //成功计数器+1
      if (this.successCount >= this.asyncArr.length) {
        //全部完成
        return this.resolve(this.responseArr);
      }
      //下一个 nextFn有可能不存在，所以不要在这做回调
      const nextFn = this.next();
      if (nextFn && this.status) {
        this.run(nextFn);
      }
    }).catch(err => {
      if (!this.status) return; //已经异常了不重复报错
      this.status = false; //标明异常
      this.errorCallBack(err);  //失败的回调
      return this.reject(err);
    });
  }
};


export default AsyncQueueManager
