<template>
  <div class="subscribe-message" v-loading.fullscreen="loading">
    <reminder title="使用须知" :text-arr="tips" :hrefConfig="tipsConfig"></reminder>
    <el-card style="margin-bottom: 20px;">
      <div class="flex-col flex-center" style="height: 80px">
        <p style="font-size: 20px;">{{count}}</p>
        <p style="font-size: 14px;">已开启订阅消息</p>
      </div>
    </el-card>
    <div class="list-btn" style="margin-bottom: 20px;">
      <el-button type="primary" size="small" @click="openAll(1)" :disabled="loading">一键开启</el-button>
      <el-button type="danger" size="small" @click="openAll(0)" :disabled="loading">一键关闭</el-button>
    </div>
    <el-table :data="tbody" class="thead-light" stripe style="width: 100%">
      <el-table-column type="index" label="序号" :width="80"></el-table-column>
      <el-table-column v-for="(th, thIndex) in thead" :key="th.prop" :label="th.label" show-overflow-tooltip
        :width="th.width" :min-width="th.minWidth" :sortable="th.sortable">
        <template slot-scope="{row}">
          {{row[th.prop] | placeholder}}
        </template>
      </el-table-column>
      <!-- <el-table-column label="模板ID" min-width="450">
        <template slot-scope="{row}">
          <popover-input-edit v-model="row.template_id" input-type="string" autofocus
            @confirm="editTemplateId(row)"></popover-input-edit>
        </template>
      </el-table-column> -->
      <el-table-column label="状态" width="150">
        <template slot-scope="{row}">
          <el-switch v-model="row.status" @change="handleStatusChange(row.id, row.status)" :active-value="1"
            :inactive-value="0"></el-switch>
          <el-popover placement="left" title="" width="280" :visible-arrow="false" trigger="hover">
            <table class="popover-table">
              <tr>
                <td style="padding-bottom: 20px" class="td-title">模板编号</td>
                <td>{{row.template_code}}</td>
              </tr>
              <tr v-for="(p) in row.preview" :key="p.key">
                <td class="td-title">{{p.key}}</td>
                <td>{{p.name}}</td>
              </tr>
            </table>
            <div @click="toDetail(row)" class="popover-detail-button">查看详情<i class="el-icon-arrow-right"></i>
            </div>
            <el-button style="margin-left: 20px;" type="text" slot="reference">预览</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Reminder from "../../common/components/Reminder";
import {
  editTemplateId,
  getSubscribeList,
  switchSubscribe,
} from "../api/subscribe-message";
import PopoverInputEdit from "../../../base/components/Popover/PopoverInputEdit";

import AQM from '@/base/utils/async-queue-manager' //异步队列管理
var manager = null

export default {
  components: { PopoverInputEdit, Reminder },
  data() {
    return {
      loading: true,
      count: 0,
      tips: [
        "使用该功能前，先绑定微信小程序",
        "使用订阅消息前，需前往微信公众平台，登录小程序账号，在“基础信息” > “服务类目”中添加“商业服务 > 企业管理”服务类目",
        "最多可同时启用 50 个订阅消息",
      ],
      tipsConfig: {
        text: "微信公众平台",
        url: "https://mp.weixin.qq.com/",
      },
      thead: [
        { label: "消息标题", prop: "name", minWidth: 100 },
        { label: "场景说明", prop: "mark", minWidth: 200 },
        { label: "服务类目", prop: "type", minWidth: 100 },
        {
          label: "添加时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
      ],
      tbody: [],
    };
  },
  computed: {
    /* 是全部开启的 */
    isAllOpened() {
      return this.tbody.filter(el => el.status === 1).length === this.tbody.length;
    },
    /* 是全部关闭的 */
    isAllClosed() {
      return this.tbody.filter(el => el.status === 0).length === this.tbody.length;
    }
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * 点击一键开启/关闭
     * @param{number} status  1: 设为开启， 0: 设为关闭
     */
    openAll(status = 1) {
      // 已经全部开启，就无需再点一键开启，关闭同理
      if (this.isAllOpened && status) return this.$message.info('已全部开启')
      if (this.isAllClosed && !status) return this.$message.info('已全部关闭')

      this.loading = true;
      manager = new AQM({maxParallel: 3})
      this.tbody.forEach(row => {
        try {
          manager.push(() => {
            return switchSubscribe(row.id, status)
          })
        } catch (e) {
          console.log(e)
        }
      })
      let resultList = []
      manager.start(function (res) {
        // 每一次请求成功的回调
        resultList.push(res)
      }).then(() => {
        if (resultList.filter(r => r.data.status === 1).length) {
          const h = this.$createElement;
          this.$msgbox({
            title: '提示',
            message: h('p', {style: 'white-space: pre-wrap;'}, resultList.map(r => r.msg).join('；\n') + '；'),
            showCancelButton: true,
            confirmButtonText: '前往',
            cancelButtonText: '取消',
          }).then(action => {
            if (action === 'confirm') {
              window.open("https://mp.weixin.qq.com/", "_blank");
            }
          }).catch(err => {
            console.log(err)
          });
        } else {
          this.$message.success('操作成功！' + (status ? '已全部开启' : '已全部关闭'))
        }
        this.getList()
      }).catch(err => {
        console.log(err)
        this.loading = false;
      })
    },
    getList() {
      this.loading = true;
      getSubscribeList()
        .then((res) => {
          const { count, list } = res.data;
          this.count = count;
          this.tbody = list;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    editTemplateId(row) {
      this.loading = true;
      editTemplateId({
        id: row.id,
        template_id: row.template_id,
      })
        .then((res) => {
          this.$message.success(res.msg);
          this.getList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    toDetail(e) {
      // console.log(e)
    },
    handleStatusChange(id, status) {
      this.loading = true;
      switchSubscribe(id, status)
        .then((res) => {
          if (res.data.status) {
            this.$msgbox
              .confirm(res.msg, "提示", {
                type: "danger",
                confirmButtonText: "前往",
              })
              .then((res) => {
                window.open("https://mp.weixin.qq.com/", "_blank");
              })
              .catch((err) => {});
          } else {
            this.$message.success(res.msg);
          }

          this.getList();
        })
        .catch((err) => {
          this.getList();
        });
    },
  },
};
</script>

<style lang="scss">
.subscribe-message {
  .el-card {
    width: 390px;
  }
}

.popover-table {
  .td-title {
    white-space: nowrap;
    width: 75px;
  }

  td {
    vertical-align: top;
    padding: 10px 0;
  }
}

.popover-detail-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 14px;
  padding: 10px 0;
  /*cursor: pointer;*/
  cursor: not-allowed;
  margin-top: 40px;

  &:hover {
    opacity: 0.7;
  }
}
</style>
