import api from '@/base/utils/request';

export const getSubscribeList = () => {
  return api({
    url: '/admin/wx_open/wx_applet_subscribe/index',
    method: 'post'
  })
}

export const switchSubscribe = (id, status) => {
  return api({
    url: '/admin/wx_open/wx_applet_subscribe/updateStatus',
    method: 'post',
    data: {id, status},
    notCancel: true
  })
}

export const editTemplateId = (data) => {
  return api({
    url: '/admin/wx_open/wx_applet_subscribe/save',
    method: 'post',
    data
  })
}
